<template>
<div>
  <div class="home">
		<img class="pic" src="@/assets/home.jpg" alt="">
	</div>
</div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    }
  },
	mounted(){
	},
  methods: {
		
  }
}
</script>
<style lang="less" scoped>
.home{
	text-align: center;
}
.pic{
	width: 400px;
}
</style>

